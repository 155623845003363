import {
  Select,
  Dialog,
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
  Option,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Button,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Input
} from "@material-tailwind/react";
import React, { useEffect } from "react";
import Table from "../../molecules/table";

import CreatorController from "../../../services/creator";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { convertNumberToDottedString, removeDotted } from "../../../utils/number";


const { Column, HeaderCell, Cell } = Table;

export default function PayoutCreator() {
  const [selectedCurrency, setSelectedCurrency] = React.useState("VND");
  const [showAddPayoutMethod, setShowAddPayoutMethod] = React.useState(false);
  const [showWithdraw, setShowWithdraw] = React.useState(false);
  const [banks, setBanks] = React.useState<any>([])
  const [payouts, setPayouts] = React.useState<any>([])
  const [selectedBank, setSelectedBank] = React.useState<any>(null)

  const { watch, handleSubmit, control, formState: { errors }, setValue } = useForm<{
    currency: string,
    name: string,
    number: string,
    bank: string
  }>()

  const getPayouts = async () => {
    const result = await CreatorController.getPayouts();
    setPayouts(result?.result || [])
  }

  const getBanks = async () => {
    const banks = await CreatorController.getBanks();
    setBanks(banks.data?.result || [])
  }

  useEffect(() => {
    getBanks()
    getPayouts()
  }, [])

  const createPayout = async (data: any) => {
    CreatorController.addPayoutMethod({
      accountName: data.name,
      accountNumber: data.number
    },
      data.bank,
      data.currency
    ).then(e => {
      toast('Add payment method success')
    })
  }

  return (
    <React.Fragment>
      <div className='font-bold text-xl'>
        Payout
      </div>

      <div>
        <Tabs id="custom-animation" value="withdraw" >
          <TabsHeader className="w-full md:w-1/2">
            <Tab value={'withdraw'} defaultChecked={true}>
              Withdraw
            </Tab>
            <Tab value={'history-payout'} defaultChecked={true}>
              History Payout
            </Tab>
            <Tab value={'payout-method'} defaultChecked={true}>
              Payout Methods
            </Tab>
          </TabsHeader>
          <TabsBody
            animate={{
              initial: { y: 250 },
              mount: { y: 0 },
              unmount: { y: 250 },
            }}
            className='p-0'
          >
            <TabPanel value={'withdraw'} className='px-0 py-2'>

              <div className='rounded border shadow-sm p-4 bg-white'>
                <div className="grid grid-cols-4">
                  <div >
                    <div className="text-gray-600 ">Available to withdraw</div>
                    <div className="flex my-2">
                      <div className="text-2xl font-bold mr-3 mt-2">0</div>
                      <div className="w-1/2 py-1"  >
                        <Menu
                          animate={{
                            mount: { y: 0 },
                            unmount: { y: 25 },
                          }}
                        >
                          <MenuHandler>
                            <Button variant="text">{selectedCurrency}</Button>
                          </MenuHandler>
                          <MenuList>
                            <MenuItem onClick={() => { setSelectedCurrency('VND') }}>VND</MenuItem>
                            <MenuItem onClick={() => { setSelectedCurrency('AUD') }}>AUD</MenuItem>
                            <MenuItem onClick={() => { setSelectedCurrency('USD') }}>USD</MenuItem>
                          </MenuList>
                        </Menu>
                      </div>
                    </div>
                    <div className="text-gray-600 ">
                      As of 09:54 PM
                    </div>
                  </div>
                  <div className='col-end-5 text-right'>
                    {
                      !payouts.length ? <Button onClick={() => { setShowAddPayoutMethod(true) }} variant="outlined">Add payout</Button> :
                        <Button onClick={() => { setShowWithdraw(true) }} color="green" variant="outlined">Withdraw</Button>
                    }

                  </div>
                </div>

              </div>

              <div className='rounded border shadow-sm p-4 bg-white my-5'>
                <div className="grid grid-cols-4">
                  <div >
                    <div className="text-gray-600 ">Holding balance </div>
                    <div className="flex my-2">
                      <div className="text-2xl font-bold mr-3 mt-2">0</div>
                      <div className="w-1/2 py-1"  >
                        <Menu
                          animate={{
                            mount: { y: 0 },
                            unmount: { y: 25 },
                          }}
                        >
                          <MenuHandler>
                            <Button variant="text">{selectedCurrency}</Button>
                          </MenuHandler>
                          <MenuList>
                            <MenuItem onClick={() => { setSelectedCurrency('VND') }}>VND</MenuItem>
                            <MenuItem onClick={() => { setSelectedCurrency('AUD') }}>AUD</MenuItem>
                            <MenuItem onClick={() => { setSelectedCurrency('USD') }}>USD</MenuItem>
                          </MenuList>
                        </Menu>
                      </div>
                    </div>
                    <div className="text-gray-600 ">
                      As of 09:54 PM
                    </div>
                  </div>

                  <div className='col-end-5 text-right'>
                    <Button variant="outlined">See holding balances</Button>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel value={'history-payout'} className='px-0 py-2'>

            </TabPanel>
            <TabPanel value={'payout-method'} className='px-0 py-2'>
              <div>
                <Table>
                  <Table.HeadRow className="text-grey-50 inter-small-semibold">
                    <Table.HeadCell>Account Name</Table.HeadCell>
                    <Table.HeadCell className="">
                      Account Number
                    </Table.HeadCell>
                    <Table.HeadCell className="">
                      Bank Name
                    </Table.HeadCell>
                    <Table.HeadCell className="">
                      Bank Code
                    </Table.HeadCell>
                    <Table.HeadCell className="">
                      Routing Code
                    </Table.HeadCell>
                  </Table.HeadRow>

                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>Nguyen Van A</Table.Cell>
                      <Table.Cell>123456789</Table.Cell>
                      <Table.Cell>ACB</Table.Cell>
                      <Table.Cell>123</Table.Cell>
                      <Table.Cell>123</Table.Cell>

                    </Table.Row>
                  </Table.Body>
                </Table>
              </div>
            </TabPanel>

          </TabsBody>
        </Tabs>
      </div>

      {/* create payout */}
      <WithdrawModal showWithdraw={showWithdraw} setShowWithdraw={setShowWithdraw} banks={banks} />
      {/* <Dialog open={showAddPayoutMethod} >
        <DialogHeader>Add payout</DialogHeader>
        <form onSubmit={handleSubmit(createPayout)}>
          <DialogBody divider>
            <div className="mb-4 flex flex-col gap-6">
              <Controller
                control={control}
                name="currency"
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value, ref } }) => {
                  return (<div>
                    <Select label="Currency" value={value} onChange={onChange} error={errors.currency}>
                      <Option value='VND'>VND</Option>
                      <Option value='AUD'>AUD</Option>
                      <Option value='USD'>USD</Option>
                    </Select>
                    {
                      errors.currency && <span className="text-red-500 ">This field is required</span>
                    }
                  </div>
                  )
                }}
              />

              <Controller
                control={control}
                name="name"
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value, ref } }) => {
                  return (
                    <div>
                      <Input label='Account name' onChange={onChange} value={value} error={errors.name} />
                      {
                        errors.name && <span className="text-red-500 ">This field is required</span>
                      }
                    </div>
                  )
                }}
              />

              <Controller
                control={control}
                name="number"
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value, ref } }) => {
                  return (
                    <div>
                      <Input label='Account number' onChange={onChange} value={value} error={errors.number} />
                      {
                        errors.number && <span className="text-red-500 ">This field is required</span>
                      }
                    </div>

                  )
                }}
              />

              <Controller
                control={control}
                name="bank"
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value, ref } }) => {
                  return (
                    <div>
                      <Select label="Bank" onChange={onChange} value={value} error={errors.bank}>
                        {
                          banks.map((bank, index) => {
                            return (
                              <Option key={index} value={bank.id}>{bank.name} ({bank.bank})</Option>
                            )
                          })
                        }
                      </Select>
                      {
                        errors.bank && <span className="text-red-500 ">This field is required</span>
                      }
                    </div>

                  )
                }}
              />



            </div>

          </DialogBody>
          <DialogFooter>
            <Button
              variant="text"
              color="red"
              onClick={() => { setShowAddPayoutMethod(false) }}
              // onClick={handleOpen}
              className="mr-1"
            >
              <span>Cancel</span>
            </Button>
            <Button type='submit' variant="gradient" color="green"
            // onClick={handleOpen}
            >
              <span>Confirm</span>
            </Button>
          </DialogFooter>

        </form>
      </Dialog> */}

      {/* create withdraw */}
    </React.Fragment >
  )
}

function WithdrawModal({
  showWithdraw = false,
  setShowWithdraw = (show) => { },
  banks = []
}) {
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue
  } = useForm()

  const createWithdraw = async (data: any) => {

  }
  return (
    <Dialog open={showWithdraw} >
      <DialogHeader>Create withdraw</DialogHeader>
      <form onSubmit={handleSubmit(createWithdraw)}>
        <DialogBody divider>
          <div className="mb-4 flex flex-col gap-6">
            <Controller
              control={control}
              name="money"
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value, ref, } }) => {
                return (
                  <div>
                    <Input label='Money'
                      onChange={(e) => {
                        if (isNaN(+removeDotted(e?.target?.value))) {
                          return;
                        }

                        e.target.value = convertNumberToDottedString(+removeDotted(e?.target?.value))
                        console.log(e?.target?.value)
                        onChange(e)
                      }} value={value} error={errors.money} />
                    {
                      errors.money && <span className="text-red-500 ">This field is required</span>
                    }
                  </div>
                )
              }}
            />

            <Controller
              control={control}
              name="accountNumber"
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value, ref } }) => {
                return (
                  <div>
                    <Input label='Account number'
                      onChange={onChange} value={value} error={errors.accountNumber} />
                    {
                      errors.accountNumber && <span className="text-red-500 ">This field is required</span>
                    }
                  </div>
                )
              }}
            />

            <Controller
              control={control}
              name="accountName"
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value, ref } }) => {
                return (
                  <div>
                    <Input label='Account Name'
                      readOnly
                      disabled
                      onChange={onChange} value={value} error={errors.accountName} />
                    {
                      errors.accountName && <span className="text-red-500 ">This field is required</span>
                    }
                  </div>
                )
              }}
            />

            <Controller
              control={control}
              name="bank"
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value, ref } }) => {
                return (
                  <div>
                    <Select label="Bank" onChange={(e) => {
                      console.log(e)
                      // setSelectedBank(e)

                      setValue('accountName', 'Nguyen Van A')
                      onChange(e)
                    }} value={value} error={errors.bank}>
                      {
                        banks?.map((bank, index) => {
                          return (
                            <Option key={index} value={bank.id}>{bank.name} ({bank.shortName})</Option>
                          )
                        })
                      }
                    </Select>
                    {
                      errors.bank && <span className="text-red-500 ">This field is required</span>
                    }
                  </div>

                )
              }}
            />

            <Controller
              control={control}
              name="description"
              defaultValue="Chuyen tien den"

              rules={{
                required: true,
              }}
              render={({ field: { onChange, value, ref } }) => {
                return (
                  <div>
                    <Input label='Account Number'
                      onChange={onChange}
                      value={value} error={errors.description} />
                    {
                      errors.description && <span className="text-red-500 ">This field is required</span>
                    }
                  </div>

                )
              }}
            />

          </div>

        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={() => { setShowWithdraw(false) }}
            // onClick={handleOpen}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
          <Button type='submit' variant="gradient" color="green"
          // onClick={handleOpen}
          >
            <span>Confirm</span>
          </Button>
        </DialogFooter>

      </form>
    </Dialog>
  )
}