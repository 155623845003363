import React from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import CreatorLayout from '../../components/creator-layout/creator-layout'
import InsightCreator from '../../components/views/creator/insight'
import PayoutCreator from '../../components/views/creator/payout'
export default function Creator() {
  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <CreatorLayout>
          <PayoutCreator />
        </CreatorLayout>
      </DndProvider>
    </>
  )
}